module.exports = [{
      plugin: require('../../../node_modules/gatsby-theme-material-ui-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"You're More Amazing Than You Think!","short_name":"ymatyt","start_url":"/","background_color":"white","theme_color":"#9c27b0","display":"minimal-ui","icon":"src/images/gatsby-icon.png"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TCSKLZB"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
